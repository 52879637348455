import React, { useContext, useEffect, useState } from "react";
import ApplicationContext from "../App/ApplicationContext";
import axios from 'axios';

const ApplicationStoreTemplate = () => {
	
	const [init, setInit] = useState(false);
	const store = useContext(ApplicationContext.ApplicationStore);
	const dispatch = useContext(ApplicationContext.Dispatch);

	useEffect(() => {
		if(!init){
			axios({method: 'get', url: 'https://nms-companion.us-east-2.elasticbeanstalk.com/api/elements?sort=name', responseType: 'json'}).then(res => {
				console.log(JSON.stringify(res, null, 2))
				dispatch({type: "SET_VALUE", field: "jsonApi", value: res.data.data})
				setInit(true);
			})
		}
	})

	return true ? (
		<>
			{<pre>store:${JSON.stringify(store, null, 2)}</pre>}
			<br />
			{<pre>dispatch:${JSON.stringify(dispatch, null, 2)}</pre>}
		</>
	) : null;
};

export default ApplicationStoreTemplate;

