import React from "react";
import ApplicationStoreTemplate from "../Templates/ApplicationStoreTemplate";
import AppWrapper from "./AppWrapper";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ElementsTemplate from "../Templates/ElementsTemplate";
import NMSComponentsTemplate from "../Templates/NMSComponentsTemplate";

const App = () => {
  return (
    <Router>
      <AppWrapper>
        <Switch>
          <Route exact path={process.env.PUBLIC_URL + "/"}>
            <div
              style={{
                marginBottom: "32px",
                fontSize: "24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Link to="elements">Elements</Link>
              <Link to="components">Components</Link>
            </div>
          </Route>
          <Route exact path={process.env.PUBLIC_URL + "/applicationStore"}>
            <ApplicationStoreTemplate />
          </Route>
          <Route exact path={process.env.PUBLIC_URL + "/elements"}>
            <ElementsTemplate />
          </Route>
          <Route exact path={process.env.PUBLIC_URL + "/components"}>
            <NMSComponentsTemplate />
          </Route>
        </Switch>
      </AppWrapper>
    </Router>
  );
};

export default App;
