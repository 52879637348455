import React, { useContext, useEffect, useState } from "react";
import ApplicationContext from "../App/ApplicationContext";
import Heading from "../atoms/Heading";
import DataLabel from "../atoms/DataLabel";
import Image from "../atoms/Image";
import axios from "axios";

const ElementsList = ({ elements }) => {
  return elements ? (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
      }}
    >
      {elements.map((item) => (
        <div
          style={{
            margin: "8px",
            padding: "8px",
            border: "1px solid black",
            width: "256px",
            minHeight: "256px"
          }}
        >
          <Heading level={2}>{item.attributes.abbrev}</Heading>
          <Heading level={3}>{item.attributes.name}</Heading>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Image src={item.attributes.image} alt={""} />
          </div>
          <DataLabel label="Group" value={item.attributes.group} />
          <DataLabel label="Added" value={item.attributes.added} />
          <DataLabel label="Rarity" value={item.attributes.rarity} />
          <DataLabel label="Value" value={item.attributes.value} />
        </div>
      ))}
    </div>
  ) : null;
};

const ElementsTemplate = () => {
  const [init, setInit] = useState(false);
  const store = useContext(ApplicationContext.ApplicationStore);
  const dispatch = useContext(ApplicationContext.Dispatch);

  useEffect(() => {
    if (!init) {
      axios({
        method: "get",
        url: "https://api.nmscompanion.app/api/elements?sort=name",
        responseType: "json",
      }).then((res) => {
        console.log(JSON.stringify(res, null, 2));
        dispatch({ type: "SET_VALUE", field: "jsonApi", value: res.data.data });
        setInit(true);
      });
    }
  });

  return init ? (
    <>
      <ElementsList elements={store.jsonApi} />
    </>
  ) : null;
};

export default ElementsTemplate;
