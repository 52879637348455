import React from "react";
import PropTypes from "prop-types";

const headingStyle = {
};

const Heading = ({ level, children }) => {
  switch (level) {
    case 1:
      return <h1 style={headingStyle}>{children}</h1>;
    case 2:
      return <h2 style={headingStyle}>{children}</h2>;
    case 3:
      return <h3 style={headingStyle}>{children}</h3>;
    case 4:
      return <h4 style={headingStyle}>{children}</h4>;
    case 5:
      return <h5 style={headingStyle}>{children}</h5>;
    case 6:
      return <h6 style={headingStyle}>{children}</h6>;
    default:
      return <div style={headingStyle}>{children}</div>;
  }
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  children: PropTypes.string,
};

export default Heading;
