import React from "react";
import { ApplicationContextProvider } from "./ApplicationContext";
import { Link } from "react-router-dom";
import Heading from "../atoms/Heading";

const AppWrapper = ({ children }) => {
  return (
    <ApplicationContextProvider>
      <div style={{ margin: "16px" }}>
        <header id="header">
          <Heading level={1}>
            <Link to="/">NMS Companion App</Link>
          </Heading>
        </header>
        <div id="main-content">{children}</div>
        <footer id="footer">
          {" "}
          <p>
            FAIR USE DISCLAIMER: This site contains copyrighted material the use
            of which has not been specifically authorized by the copyright
            owner, Hello Games. We are making such material available in our
            efforts to document No Man's Sky items and game mechanics. We
            believe this constitutes a 'fair use' of any such copyrighted
            material as provided for in section 107 of the US Copyright Law.
          </p>
          <p>
            Any of the trademarks or similar rights that are mentioned or used
            in any page are the property of their respective owners. Unless
            otherwise stated, NMS Companion App is neither endorsed nor affiliated with
            any of the holders of any such rights, and as such cannot grant any
            rights to use any otherwise protected materials.
          </p>
          <p>Created by Jake Rathman</p>
        </footer>
      </div>
    </ApplicationContextProvider>
  );
};

export default AppWrapper;
