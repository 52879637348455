import React, { createContext, useReducer } from "react";

const ACTIONS = {
	SET_VALUE: "SET_VALUE"
};

const ApplicationStore = createContext();
const Dispatch = createContext();

const defaultState = {
	selected: null,
	view: null,
	debug: false
};

const applicationStoreReducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.SET_VALUE:
			return { ...state, [action.field]: action.value };
		default:
			return state;
	}
};

export const ApplicationContextProvider = ({ children, initialState }) => {
	const [applicationStore, dispatch] = useReducer(applicationStoreReducer, initialState ? initialState : defaultState);

	return (
		<ApplicationStore.Provider value={applicationStore}>
			<Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
		</ApplicationStore.Provider>
	);
};

const ApplicationContext = {
	Dispatch: Dispatch,
	ApplicationStore: ApplicationStore
};

export default ApplicationContext;
