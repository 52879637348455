import React, { useContext, useEffect, useState } from "react";
import ApplicationContext from "../App/ApplicationContext";
import Heading from "../atoms/Heading";
import DataLabel from "../atoms/DataLabel";
import Image from "../atoms/Image";
import axios from "axios";

const ComponentsList = ({ elements }) => {
  return elements ? (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
      }}
    >
      {elements.map((item) => (
        <div
          style={{
            margin: "8px",
            padding: "8px",
            border: "1px solid black",
            height: "256px",
            width: "256px",
          }}
        >
          <Heading level={3}>{item.attributes.name}</Heading>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Image src={item.attributes.image} alt={""} />
          </div>
          <DataLabel label="Value" value={item.attributes.value} />
          <DataLabel label="Identifier" value={item.id} />
        </div>
      ))}
    </div>
  ) : null;
};

const UpdateNMSComponent = () => {
  const defaultJson = {
    data: {
      id: null,
      attributes: {
        name: "",
        image: "",
        value: 0,
      },
    },
  };

  const [textAreaValue, setTextAreaValue] = useState(defaultJson);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
      }}
    >
      <Heading level={2}>Create or Update</Heading>
      <br />
      <textarea
        rows={20}
        onChange={(e) => {
          setTextAreaValue(e.target.value);
        }}
      >
        {JSON.stringify(textAreaValue, null, 2)}
      </textarea>
      <br />
      <button
        onClick={() => {
          console.log(textAreaValue);
          axios({
            method: "post",
            url: "https://api.nmscompanion.app/api/components",
            responseType: "json",
            data: textAreaValue,
            headers: { "Content-Type": "application/vnd.api+json" },
          }).then((res) => {
            console.log(JSON.stringify(res, null, 2));
          });
        }}
      >
        Save
      </button>
    </div>
  );
};

const NMSComponentsTemplate = () => {
  const [init, setInit] = useState(false);
  const store = useContext(ApplicationContext.ApplicationStore);
  const dispatch = useContext(ApplicationContext.Dispatch);

  useEffect(() => {
    if (!init) {
      axios({
        method: "get",
        url: "https://api.nmscompanion.app/api/components?sort=name",
        responseType: "json",
      }).then((res) => {
        console.log(JSON.stringify(res, null, 2));
        dispatch({ type: "SET_VALUE", field: "jsonApi", value: res.data.data });
        setInit(true);
      });
    }
  });

  return init ? (
    <>
      <ComponentsList elements={store.jsonApi} />
      {true ? null : <UpdateNMSComponent />}
    </>
  ) : null;
};

export default NMSComponentsTemplate;
